<template>
  <div class="home">
    <HelloWorld msg="Home!" v-if="!this.$route.params.code" />
    <ClientInfo v-if="this.$route.params.code" />
    <TheForm msg="The Form!" v-if="this.$route.params.code" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import ClientInfo from "@/components/ClientInfo.vue";
import TheForm from "@/components/TheForm.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
    ClientInfo,
    TheForm,
  },
};
</script>
