<template>
  <div class="form container">
    <form
      class="container row"
      v-on:submit.prevent="submitForm"
      v-if="!this.send"
    >
      <div class="mb-3 col-6">
        <label for="lastname" class="form-label">Nom*</label>
        <input
          type="text"
          class="form-control"
          id="lastname"
          v-model="form.lastname"
        />
      </div>
      <div class="mb-3 col-6">
        <label for="firstname" class="form-label">Prénom</label>
        <input
          type="text"
          class="form-control"
          id="firstname"
          v-model="form.firstname"
        />
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Courriel*</label>
        <input
          type="email"
          class="form-control"
          id="email"
          aria-describedby="emailHelp"
          v-model="form.email"
        />
        <div id="emailHelp" class="form-text">
          Votre adresse email ne sera jamais transmise à quiconque.
        </div>
      </div>
      <div class="mb-3">
        <label for="street" class="form-label">Adresse</label>
        <input
          type="text"
          class="form-control"
          id="street"
          v-model="form.street"
        />
      </div>
      <div class="mb-3 col-4">
        <label for="zip" class="form-label">Code postal*</label>
        <input type="text" class="form-control" id="zip" v-model="form.zip" />
      </div>
      <div class="mb-3 col-8">
        <label for="city" class="form-label">Ville</label>
        <input type="text" class="form-control" id="city" v-model="form.city" />
      </div>
      <h3>Votre demande</h3>
      <div class="mb-3">
        <label for="subject" class="form-label">Objet de la demande*</label>
        <input
          type="text"
          class="form-control"
          id="subject"
          v-model="form.subject"
        />
      </div>
      <div class="mb-3">
        <label for="message" class="form-label">Message</label>
        <textarea
          class="form-control"
          id="message"
          rows="7"
          v-model="form.message"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="emergency">Degré d'urgence ?</label>
        <star-rating
          class="d-block"
          :rounded-corners="true"
          :border-width="6"
          :show-rating="false"
          @update:rating="setRating"
        />
        <br />
      </div>
      <br />
      <button type="submit" class="btn btn-primary">Envoyer la demande</button>
      <br />
    </form>
    <br /><br />
    <h1 v-if="this.success">
      C'est fait, votre demande à bien été envoyée. Merci.
    </h1>
    <h2 v-if="this.error">Une erreur est survenue : {{ error_msg }}</h2>
  </div>
</template>

<script>
import axios from "axios";
import StarRating from "vue-star-rating";
export default {
  name: "TheForm",
  components: {
    StarRating,
  },
  data() {
    return {
      name: "company name",
      form: {
        lastname: "",
        firstname: "",
        email: "",
        street: "",
        street2: "",
        zip: "",
        city: "",
        subject: "",
        emergency: "0",
        message: "",
        code: this.$route.params.code,
      },
      send: 0,
      success: 0,
      error: 0,
      error_msg: "",
    };
  },
  methods: {
    setRating(rating) {
      this.form.emergency = rating;
    },
    submitForm() {
      var formData = new FormData();
      formData.append("lastname", this.form.lastname);
      formData.append("firstname", this.form.firstname);
      formData.append("email", this.form.email);
      formData.append("street", this.form.street);
      formData.append("zip", this.form.zip);
      formData.append("city", this.form.city);
      formData.append("subject", this.form.subject);
      formData.append("message", this.form.message);
      formData.append("emergency", this.form.emergency);
      formData.append("code", this.form.code);
      axios
        .post("https://api.msel.app/wp-json/wp/v2/prospects", formData)
        .then((res) => {
          //Perform Success Action
          console.log(res);
          this.success = 1;
        })
        .catch((error) => {
          // error.response.status Check status code
          console.log(error);
          this.error = 1;
          this.error_msg = error;
        })
        .finally(() => {
          //Perform action in always
          this.send = 1;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
  color: #336a86;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

button {
  background-color: #8eaec4;
  border-color: #8eaec4;
}

.vue-star-rating {
  display: block;
}
</style>
