<template>
  <div class="info">
    <h2>
      Remplissez ce formulaire et je vous réponds dans les plus brefs délais.
    </h2>

    <h3>{{ this.name }}</h3>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ClientInfo",
  data() {
    return {
      name: "company name",
    };
  },
  mounted() {
    /*axios
      .get(
        "https://new.oli-via-net.fr/wp-json/wp/v2/clients/form?aabg%C2%A3]SD"
      )
      .then((response) => {
        console.log(response);
        this.name = response.data["company"];
      });*/
    axios
      .get(
        "https://api.msel.app/wp-json/wp/v2/clients/form?" +
          this.$route.params.code
      )
      .then((response) => {
        console.log(response);
        this.name = response.data["display_name"];
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2,
h3 {
  color: #336a86;
}
</style>
